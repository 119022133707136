import MuiTabs from '@mui/material/Tabs'
import { styled } from '@mui/system'

const shouldForwardProp = (prop) => !['aboveTheFold'].includes(prop)

const Tabs = styled(MuiTabs, { shouldForwardProp })`
	.MuiTabs-flexContainer {
		height: 100%;
		justify-content: ${({ alignment }) => {
			switch (alignment) {
				case 'left':
					return 'flex-start'
				case 'center':
					return 'center'
				case 'right':
					return 'flex-end'
			}
		}};
	}
	.MuiTabs-scrollableX {
		.MuiTabs-flexContainer {
			${({ theme }) => theme.breakpoints.down('md')} {
				justify-content: ${({ alignment }) => {
					switch (alignment) {
						// center is not scrollable on mobile
						case 'right':
							return 'flex-end'
						default:
							return 'flex-start'
					}
				}};
			}
		}
	}
`

export { Tabs }
